$mainColor: #a53c46;

* {
    box-sizing: border-box;
}

.garden {
    .Title {
        color: #000;
    }

    .Dashes {
        color: #ece7e5;
        padding-left: 10px
    }

    .Quotation {
        color: #262626;
    }
}

.manifesto {
    .Title {
        color: #fff;
    }

    .Dashes {
        color: #333;
    }

    .Quotation {
        color: #ece7e5;
    }
}

.Title {
    display: block;
    font-family: Poppins;
    font-size: 60px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.03;
    letter-spacing: normal;
    text-align: left;
}

.Quotation {
    display: block;
    font-family: Ubuntu Mono;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.08px;
}

.Red-highlighting {
    display: inline-block;

    padding: 0 8px 0 12px;
    border-radius: 10px;
    -webkit-box-decoration-break: clone;
    background: linear-gradient(104deg, rgba(255, 130, 130, 0) .9%, #ff8282 2.4%, rgba(255, 130, 130, .5) 5.8%, rgba(255, 130, 130, .1) 93%, rgba(255, 130, 130, .7) 96%), linear-gradient(183deg, rgba(130, 255, 173, 0), rgba(255, 107, 152, .3) 7.9%, rgba(255, 130, 130, 0) 15%);
    text-shadow:-12px 12px 9.8px rgba(255, 130, 130, .7), 21px -18.1px 7.3px #fff, -18.1px -27.3px 30px #fff;
    background-color: #ffcaca;
    font-weight: bold;

    font-family: Ubuntu Mono;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.08px;
    text-align: left;
    color: #262626;
}

.Black-button {
    display: inline;
    vertical-align: middle;

    text-decoration: none;
    line-height: normal;

    border-radius: 20px;
    background-color: #000;
    padding: 10px;

    text-align: center;
    font-family: Ubuntu Mono;
    font-size: 14px;
    color: #fff;
}

@media screen and (max-width: 1024px) {
    .Title {
        font-size: 24px;
    }
}


@media screen and (max-width: 1024px) {
    .mobile-hidden {
        display: none !important;
    }
}