.bottombar {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 98px;
    background: #E5E5E5;
    z-index: 99;
    display: flex;
    padding: 10px 8px 10px 8px;
    gap: 9px;
    .item {
        flex: 1;
        background: white;
        border-radius: 10px;
        padding: 28px 18px;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        width: 100%;
        height: 100%;
        text-decoration: none !important;
        &:hover {
            text-decoration: none !important;
        }
        .number {
            color: rgba(0, 0, 0, 0.50);
            font-family: Ubuntu Mono;
            font-weight: 400;
            letter-spacing: 0.072px;
            font-size: 12px;
        }
        .navigation {
            color: #000;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
        }
    }
}


.item-active {
    background: #A53C46 !important;
    .number {
        color: rgba(255, 255, 255, 0.50) !important;
    }
    .navigation {
        color: white !important
    }
}

@media (min-width: 1024px) {
    .bottombar.hidden {
      display: none;
    }
  }