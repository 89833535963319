@import "../../global.scss";

.contact {
    padding-bottom: 3rem;
    .title-position {
        margin: 350px 0 0 173px;
    }

    .quotation-position-1 {
        margin: 48px 0 0 173px;
    }

    .line-break {
        width: 606px;
        height: 2px;
        margin: 16px 0 0 173px;
        border-radius: 10px;
        background-color: #ece7e5;
    }

    .personal-info-box {
        margin: 14px 0 106px 155px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 630px;

            .link {
                text-decoration: none;
                color: inherit;

                .tile {
                    display: block;
                    width: 294px;
                    height: 83px;
                    margin: 18px 0 0 18px;
                    border-radius: 5px;
                    background-color: #e5e5e5;
                    position: relative;

                    .bird-image {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }

                    .title {
                        font-family: Poppins;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 1.03;
                        color: #000;

                        width: 100%;
                        position: absolute;
                        top: 34px;
                        left: 100px;
                    }

                    .message {
                        font-family: Ubuntu Mono;
                        font-size: 12px;
                        line-height: 2;
                        letter-spacing: 0.07px;
                        color: #262626;

                        width: 100%;
                        position: absolute;
                        top: 51px;
                        left: 100px;
                    }

                    &:hover {
                        background-color: #000;

                        .title {
                            color: #fff;
                        }

                        .message {
                            color: #fff;
                        }
                    }
                }
            }


   

    }
}

@media screen and (max-width: 768px) {
    .contact {
        padding: 118px 16px;
        overflow-x: hidden;
    }
    .contact .quotation-position-1 {
        margin: 16px 0 !important;
    }
    .personal-info-box {
        margin: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        gap: 16px;
    }
        .contact .personal-info-box .link .tile {
        width: 100% !important;
        margin: 0 !important;
    }
}