@import "../../global.scss";

.right {
    .image {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 45%;

    z-index: 8;
    background-color: transparent;
    }
}

@media only screen and (max-width: 600px) {
    .image-mobile {
        position: absolute !important;
        top: 65% !important;
        width: 248px !important;
        height: 371px !important;
        right: 0 !important
    }
}