@import "../../global.scss";

.hide-desktop {
  display: none;
}

.portfolio {
  scrollbar-width: none; // for firefox
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }

  .title-position {
    margin: 316px 0 0 173px;
  }

  .red-highlight-position {
    margin: 48px 0 0 173px;
  }
  .quotation-position-2 {
    position: relative;
    padding: 50px 173px;

    .black-button-sizing {
      margin-left: 8px;
      width: 110px;
      height: 30px;
    }
  }
  .quotation-position-1 {
    margin: 4px 0 0 173px;
  }

  .portfolio-section {
    margin: 54px 0 0 173px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);

    width: 660px;

    .box {
      display: flex;
      border: solid 1px #ece7e5;
      position: relative;
      min-height: 180px;
      justify-content: center;
      .box-wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 22;
        justify-content: flex-start;
        align-items: center;
        .image-box {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 2rem;
          .image-hover {
            display: none;
            z-index: 100;
          }
        }
        &:hover {
          height: 240px;
          width: 180px;
          z-index: 100;
          border: 0px;
          background-color: $mainColor;
          margin: 0 auto;
          .image-box {
            .image {
              display: none;
            }

            .image-hover {
              display: inline;
            }
          }

          .portfolio-position {
            left: 16px;
            bottom: 74px;
            color: #c2c2c2;
          }

          .portfolio-title {
            left: 16px;
            bottom: 98px;
            color: white;
          }

          .portfolio-description {
            display: block;
          }
        }
      }

      .portfolio-title {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 800;
        line-height: 1.03;
        color: #000;

        width: 100%;
        position: absolute;
        left: 16px;
        bottom: 40px;
      }

      .portfolio-position {
        height: 24px;
        font-family: Ubuntu Mono;
        font-size: 11px;
        line-height: 2.18;
        letter-spacing: 0.07px;
        color: #000;

        width: 100%;
        position: absolute;
        left: 16px;
        bottom: 16px;
      }

      .portfolio-description {
        font-family: Ubuntu Mono;
        font-size: 11px;
        line-height: 1.64;
        letter-spacing: 0.07px;
        color: #fff;

        width: 100%;
        position: absolute;
        left: 16px;
        bottom: 20px;

        display: none;

        .italics {
          font-style: italic;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .title-position {
    margin-bottom: 12px !important;
  }
  .portfolio {
    padding: 112px 16px;
  }
  .hide-desktop {
    display: block;
  }
  .hide-mobile {
    display: none !important;
  }
  .title-position {
    margin: 0 !important;
  }
  .box {
    position: relative !important;
    top: unset !important;
    left: unset !important;
    min-height: 84px !important;
  }
  .portfolio-section {
    margin: 0 !important;
    width: 100% !important;
    margin: 24px 0 !important;
  }
  .image {
    width: 70%;
  }

  .box-wrapper {
    justify-content: center !important;
    &:hover {
      height: 100% !important;
      width: 100% !important;
      transform: scale(1.1) !important;
      transition: 0.2s linear all;
      z-index: 100;
      border: 0px;
      background-color: $mainColor;
    }
  }

  .image-box {
    margin: 0 !important;
  }

  .experience-company {
    display: flex;
    align-items: center;
    color: #000;
    gap: 4px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 103.5%;
  }
  .experience-dash {
    color: #c2c2c2;

    font-family: Ubuntu Mono;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
    letter-spacing: 0.072px;
  }
  .experience-duty {
    color: #262626;

    font-family: Ubuntu Mono;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.072px;
  }

  hr {
    height: 1px;
    color: #ece7e5;
    border-top-width: 1px;
    margin: 1rem 0;
    transform: scaleY(0.3);
  }
}
